import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { JwtAuthService } from '../services/auth/jwt-auth.service';
import { Observable, of, switchMap } from 'rxjs';
import { SubscriptionEntityService } from '../../state/entity-services/subscription-entity.service';
import { map } from 'rxjs/operators';
import { OrganizationService } from '../services/organization.service';
import { Subscription } from '../../state/models/subscription';
import { Organization } from '../../state/models/organization';

@Injectable()
export class OrganizationGuard implements CanActivate {
  constructor(
    private organizationService: OrganizationService,
    private subscriptionEntityService: SubscriptionEntityService,
    private router: Router, private jwtAuth: JwtAuthService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {

    const { person_id } = route.queryParams;
    const user = this.jwtAuth.getUser();
    const personId = person_id || user?.id;

    if (!this.jwtAuth.isLoggedIn) {
      return of(this.organizationService.setOrganization());
    }

    return this.getUserSubscription(personId).pipe(
      map(subscription => {
        const organizationMar = this.getOrganizationFromSubscription(subscription);
        return this.organizationService.setOrganization(organizationMar);
      })
    );
  }

  private getUserSubscription(personId: any): Observable<Subscription> {
    return this.jwtAuth.getUser() ? this.fetchUserSubscription(personId) : this.handleInvalidToken(personId);
  }

  private fetchUserSubscription(personId: any): Observable<Subscription> {
    return this.querySubscription(personId);
  }

  private handleInvalidToken(personId: any): Observable<Subscription> {
    return this.jwtAuth.checkTokenIsValid().pipe(
      switchMap(() => this.fetchUserSubscription(personId))
    );
  }

  private querySubscription(personId: number): Observable<Subscription> {
    return this.subscriptionEntityService.getWithQuery({
      personIds: personId,
      active: true
    }).pipe(
      map(subscriptions => subscriptions.find(subscription => !subscription.organization.parent))
    );
  }

  private getOrganizationFromSubscription(subscription?: Subscription): Organization | undefined {
    return subscription?.organization;
  }
}


