import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';

// SERVICES
import { AuthGuard } from './guards/auth.guard';
import { ConfirmService } from './services/app-confirm/confirm.service';
import { RoutePartsService } from './services/route-parts.service';

import { FormlyModule } from '@ngx-formly/core';
import { FileUploadComponent } from '../form/components/file-upload/file-upload.component';
import { NgxFileDropModule } from 'ngx-file-drop';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SharedComponentsModule } from './components/shared-components.module';
import { RouterModule } from '@angular/router';
import { PersonReturnIconPipe } from './pipes/person-return-icon.pipe';
import { BottomSheetDraggableDirective } from './directives/bottom-sheet-draggable.directive';
import { OrganizationGuard } from './guards/organization.guard';
import { PatientStatusGuard } from './guards/patient.status.guard';
import { SummaryStatusGuard } from './guards/summary.status.guard';
import { OrganizationWhitelabelGuard } from './guards/organization-whitelabel.guard';
import { LegalTermsGuard } from './guards/legal-terms.guard';
import { SafePipe } from './pipes/safe.pipe';
import { TranslationPipe } from './pipes/translation.pipe';
import { DatePipe } from './pipes/date.pipe';
import { NumeralPipe } from './pipes/numeral.pipe';
import { MatFormField } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { PatientWaitingFormSubmissionGuard } from './guards/patient-waiting-form-submission-guard.service';
import { OrganizationInitGuard } from './guards/organization-init.guard';

@NgModule({
  declarations: [
    NumeralPipe,
    DatePipe,
    TranslationPipe,
    FileUploadComponent,
    PersonReturnIconPipe,
    BottomSheetDraggableDirective,
    SafePipe
  ],
  imports: [
    NgOptimizedImage,
    RouterModule,
    SharedComponentsModule,
    MatTooltipModule,
    MatIconModule,
    NgxFileDropModule,
    CommonModule,
    FormlyModule,
    MatButtonModule,
    MatFormField,
    MatInput,
    ReactiveFormsModule,
  ],
  providers: [
    OrganizationInitGuard,
    RoutePartsService,
    PatientWaitingFormSubmissionGuard,
    LegalTermsGuard,
    AuthGuard,
    ConfirmService,
    OrganizationWhitelabelGuard,
    OrganizationGuard,
    PatientStatusGuard,
    SummaryStatusGuard
  ],
  exports: [
    NgOptimizedImage,
    NumeralPipe,
    DatePipe,
    TranslationPipe,
    SafePipe,
    BottomSheetDraggableDirective,
    PersonReturnIconPipe,
    FileUploadComponent,
    FormlyModule,
  ],
})
export class SharedModule {
}
