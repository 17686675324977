import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from 'ngx-flexible-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { FormlyMatDatepickerModule } from '@ngx-formly/material/datepicker';
import { FormlyMatInputModule } from '@ngx-formly/material/input';
import { FormlyMatRadioModule } from '@ngx-formly/material/radio';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { NgxFileDropModule } from 'ngx-file-drop';
import { addonsExtension } from './addons/addons.extension';
import { FormlyWrapperAddons } from './addons/addons.wrapper';
import {
  FormlyFieldCurrencyTypeComponent
} from './components/formly-field-currency-type/formly-field-currency-type.component';
import {
  FormlyFieldMaskedTypeComponent
} from './components/formly-field-masked-type/formly-field-masked-type.component';
import { FlexLayoutType } from './components/formly-flex-layout';
import { GenericValidator } from './services/generic.validator';
import { FormlyMatToggleModule } from '@ngx-formly/material/toggle';
import { SharedMaterialModule } from '../shared/shared-material.module';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { ValidatorCpf } from './validators/validator-cpf';
import { PdfPasswordComponent } from './components/pdf-password/pdf-password.component';

@NgModule({
  declarations: [
    PdfPasswordComponent,
    FormlyWrapperAddons,
    FlexLayoutType,
    FormlyFieldCurrencyTypeComponent,
    FormlyFieldMaskedTypeComponent,
  ],
  imports: [
    SharedMaterialModule,
    NgxMaskDirective,
    NgxMaskPipe,
    NgxFileDropModule,
    FormlyMatDatepickerModule,
    FormlyMatToggleModule,
    FlexLayoutModule,
    FormsModule,
    CommonModule,
    FormlyMatRadioModule,
    MatFormFieldModule,
    MatInputModule,
    FormlyMatInputModule,
    FormlyMaterialModule,
    CurrencyMaskModule,
    ReactiveFormsModule,
    FormlyModule.forRoot({
      wrappers: [{ name: 'addons', component: FormlyWrapperAddons }],
      extensions: [
        { name: 'addons', extension: { onPopulate: addonsExtension } },
      ],
      validators: [
        {
          name: 'fieldMatch',
          validation: GenericValidator.fieldMatchValidator,
        },
        { name: 'cpf', validation: ValidatorCpf },
        { name: 'cnpj', validation: GenericValidator.isValidCnpj },
      ],
      validationMessages: [
        { name: 'required', message: 'Este campo é obrigatório' },
        {
          name: 'minlength',
          message: GenericValidator.formlyMinLengthValidationMessage,
        },
        {
          name: 'cpf',
          message: 'CPF inválido',
        }
      ],
      types: [
        { name: 'flex-layout', component: FlexLayoutType },
        {
          name: 'radio',
          defaultOptions: {
            props: {
              appearance: 'fill',
            },
          },
        },
        {
          name: 'masked',
          component: FormlyFieldMaskedTypeComponent,
          defaultOptions: {
            props: {
              type: 'phone',
            },
          },
        },
        {
          name: 'currency',
          component: FormlyFieldCurrencyTypeComponent,
          defaultOptions: {
            props: {},
          },
        },
      ],
    }),
    MatIconModule,
  ],
  providers: [
    provideNgxMask(),
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline' } }
  ],
  exports: [
    FormlyMatDatepickerModule,
    FormlyMatInputModule,
    FormlyMaterialModule,
    FormlyFieldCurrencyTypeComponent,
    FormsModule,
    ReactiveFormsModule,
    FormlyModule,
  ],
})
export class FormModule {
}
