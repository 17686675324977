import { Injectable } from '@angular/core';
import { RouterParamsService } from '../../shared/services/router-params.service';
import { ExamRequest } from '../models/exams';
import { BaseEntityService } from '../entity-management/services/base/base-entity.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ExamRequestsEntityService extends BaseEntityService<ExamRequest> {

  constructor(
    httpClient: HttpClient,
    routerParamsService: RouterParamsService,
  ) {
    super(
      httpClient,
      environment.apiUrl, { singular: 'examRequest', plural: 'examRequests', url: 'exam-requests' },
      routerParamsService
    );
    this.nameId = 'examRequestId';
  }


  regenerateDocument(examRequestId: number): Observable<any> {
    return this.httpClient
      .post(`${ this.entityUrl }/${ examRequestId }/regenerate-document`, {})
      .pipe(map((response: any) => response?.autoGenerate));
  }
}
