import { Injectable } from '@angular/core';
import { from, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import libheif from 'libheif-js/wasm-bundle';

@Injectable({
  providedIn: 'root'
})
export class ImageConverterService {

  constructor() {
  }

  renameFileExtension(fileName: string): string {
    return `${ fileName.replace(/\.[^/.]+$/, '') }.jpeg`;
  }

  isHeicFile(file: File): boolean {
    const fileExtension = file?.name?.split('.').pop()?.toLowerCase();
    const mimeType = file.type;
    return fileExtension === 'heic' || fileExtension === 'heif' || mimeType === 'image/heic' || mimeType === 'image/heif';
  }

  convertHeicToJpeg(file: File, quality: number = 0.7): Observable<any> {
    return from(
      new Promise<Blob>(async (resolve, reject) => {
        try {
          // Verifica se o arquivo é do tipo HEIC ou HEIF
          if (file.type !== 'image/heic' && file.type !== 'image/heif') {
            return reject(new Error('O arquivo não é do tipo HEIC ou HEIF.'));
          }

          // Lê o conteúdo do arquivo como ArrayBuffer
          const arrayBuffer = await file.arrayBuffer();

          // Inicializa o decodificador HEIF
          const heif = libheif;
          const data = new Uint8Array(arrayBuffer);
          const decoder = new heif.HeifDecoder();
          const images = decoder.decode(data.buffer);
          const image = images[0];

          // Obtém as dimensões da imagem
          const width = image.get_width();
          const height = image.get_height();

          // Cria um canvas para desenhar a imagem
          const canvas = document.createElement('canvas');
          canvas.width = width;
          canvas.height = height;
          const ctx = canvas.getContext('2d');
          if (!ctx) {
            return reject(new Error('Erro ao obter o contexto do canvas.'));
          }

          // Cria um ImageData para armazenar os dados da imagem
          const imageData = ctx.createImageData(width, height);

          // Decodifica a imagem e preenche o ImageData
          await new Promise<void>((res, rej) => {
            image.display(imageData, (displayData) => {
              if (!displayData) {
                return rej(new Error('Erro ao processar a imagem HEIF.'));
              }
              res();
            });
          });

          // Desenha a imagem no canvas
          ctx.putImageData(imageData, 0, 0);

          // Converte o canvas para Blob no formato JPEG
          canvas.toBlob(
            (blob) => {
              if (blob) {
                resolve(blob);
              } else {
                reject(new Error('Erro ao criar o Blob da imagem convertida.'));
              }
            },
            'image/jpeg',
            quality
          );
        } catch (error) {
          reject(error);
        }
      })
    ).pipe(
      map((blob) => {
        // Retorna o Blob resultante
        return blob;
      })
    );
  }


  checkIsResizeImage(file: File, maxWidth: number = 1500): Observable<File> {
    const mimeTypes = [
      'image/png',
      'image/jpeg',
      'image/jpg',
      'image/heic',
      'image/heif',
    ];

    if (!mimeTypes.includes(file.type)) {
      return of(file);
    }

    return from(new Promise<File>(async (resolve, reject) => {
      let fileToProcess = file;
      try {
        const img = new Image();
        const reader = new FileReader();

        reader.onload = (event) => {
          img.src = event.target?.result as string;
        };

        reader.onerror = (error) => reject(error);

        img.onload = () => {
          let { width, height } = img;

          if (width > maxWidth) {
            const scaleFactor = maxWidth / width;
            width = maxWidth;
            height = Math.round(height * scaleFactor);
          }

          const canvas = document.createElement('canvas');
          canvas.width = width;
          canvas.height = height;

          const ctx = canvas.getContext('2d');
          if (!ctx) {
            return reject(new Error('Erro ao criar o contexto do canvas'));
          }

          ctx.drawImage(img, 0, 0, width, height);

          canvas.toBlob((blob) => {
            if (blob) {
              const resizedFile = new File([blob], file.name, { type: file.type });
              resolve(resizedFile);
            } else {
              reject(new Error('Erro ao criar o Blob da imagem redimensionada'));
            }
          }, fileToProcess.type);
        };

        img.onerror = (error) => reject(error);
        reader.readAsDataURL(fileToProcess);
      } catch (error) {
        console.error(error);
        reject(error);
      }
    }));
  }

}
