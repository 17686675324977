import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, Inject } from '@angular/core';
import { DataConfirm } from './pdf-password.service';

@Component({
  selector: 'pdf-password',
  styleUrls: ['./pdf-password.component.scss'],
  template: `
    <mat-card>
      <h1 matDialogTitle class="mb-8">{{ data.title }}</h1>
      <div mat-dialog-content class="mb-16">
        
        <div *ngIf="data?.message" [innerHTML]="data?.message"></div> <br/><br/>

        <mat-form-field class="example-form-field">
          <mat-label>Senha do arquivo</mat-label>
          <input matInput type="password" [(ngModel)]="value">
          @if (value) {
            <button matSuffix mat-icon-button aria-label="Clear" (click)="value=''">
              <mat-icon>close</mat-icon>
            </button>
          }
        </mat-form-field>
      </div>
      <div mat-dialog-actions class="pb-16">
        <div fxFill fxLayout="row" fxLayout.xs="column" fxLayoutGap="8px">
          <div fxFlex="100">
            <button
              fxFlex="100"
              mat-dialog-close
              mat-stroked-button
              type="button"
              (click)="dialogRef.close(false)"
            >
              Cancelar
            </button>
          </div>
          <div fxFlex="100">
            <button
              fxFlex="100"
              mat-raised-button
              type="submit"
              color="primary"
              [disabled]="!value.length"
              (click)="dialogRef.close(value)"
              class="w-100 ml-none"
            >
             Enviar
            </button>
          </div>
        </div>
      </div>
    </mat-card>
  `,
})
export class PdfPasswordComponent {
  value: string = '';
  constructor(
    public dialogRef: MatDialogRef<PdfPasswordComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DataConfirm,
  ) {
  }
}
