import { Observable } from 'rxjs';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Injectable } from '@angular/core';

import { PdfPasswordComponent } from './pdf-password.component';

@Injectable({
  providedIn: 'root'
})
export class PdfPasswordService {
  constructor(private dialog: MatDialog) {
  }

  public confirm(): Observable<any> {
    const nData = {
      title: 'Arquivo protegido por senha',
      message: 'Para podermos ler seu arquivo, vamos precisar da senha.',
    };
    let dialogRef: MatDialogRef<PdfPasswordComponent>;
    dialogRef = this.dialog.open(PdfPasswordComponent, {
      width: '500px',
      disableClose: true,
      data: nData
    });
    return dialogRef.afterClosed();
  }
}

export interface DataConfirm {
  title: string;
  message?: string;
}
